import { Domain } from "frontend/lib/get-domain";
import {
  OrganizationEntry,
  PersonEntry,
  PersonWrapperEntry,
  SchoolLeadershipPersonDetailsEntry,
} from "frontend/contentful/schema/semantics";
import { ContentfulIncludes, EntryRef } from "../schema/sys";
import { resolveEntry } from "./resolve-includes";
import { resolveEntries } from "./resolve-entries";
import { resolveEntryUrl } from "./resolve-entry-url";
import { isContentType } from "frontend/contentful/lib/is-content-type";
import { resolveIsPersonPastHbsFaculty } from "./resolve-is-person-past-hbs-faculty";
import { SiteEntry } from "../schema/universal";
import { getSiteId } from "frontend/lib/get-site-id";

interface ResolveEntityLinkArgs {
  entry?: EntryRef<
    | PersonEntry
    | PersonWrapperEntry
    | SchoolLeadershipPersonDetailsEntry
    | OrganizationEntry
  >;
  domain: Domain | undefined;
  site?: SiteEntry;
  includes: ContentfulIncludes;
}

export function resolveEntityLink({
  entry,
  domain,
  site,
  includes,
}: ResolveEntityLinkArgs): string {
  if (!entry) return ""; // Short-circuit if no entry

  const entity = resolveEntry(includes, entry);
  if (!entity) return ""; // Short-circuit if entity cannot be resolved

  // Short circuit for OrganizationEntry (no URL)
  if (isContentType<OrganizationEntry>("organization", entity)) {
    return "";
  }

  // Handle PersonEntry case
  if (isContentType<PersonEntry>("person", entity)) {
    const isHbsFaculty = resolveEntries({
      includes,
      refs: entity.fields.personType,
    }).some((type) => type.fields.title === "HBS Faculty");

    // Try to resolve bioURL
    const bioUrl = resolveEntry(includes, entity.fields.bioURL)?.fields?.url;
    if (bioUrl) {
      if (domain && !bioUrl.startsWith("https://")) {
        return (
          resolveEntryUrl({
            domain,
            entry: resolveEntry(includes, entity.fields.bioURL),
            includes,
          }) || ""
        );
      }
      return bioUrl; // Short-circuit if bioUrl is found
    }

    // Handle case for HBS Faculty ID
    if (isHbsFaculty && entity.fields.hbsEnterpriseID) {
      return `https://www.hbs.edu/faculty/Pages/profile.aspx?facId=${entity.fields.hbsEnterpriseID}`;
    }
  }

  // Handle SchoolLeadershipPersonDetailsEntry case
  if (
    isContentType<SchoolLeadershipPersonDetailsEntry>(
      "schoolLeadershipPersonDetails",
      entity,
    )
  ) {
    const leadershipUrl = resolveEntry(includes, entity.fields.link)?.fields
      ?.url;
    if (leadershipUrl) return leadershipUrl; // Short-circuit if found

    // Resolve person entry if needed
    const person = resolveEntry(includes, entity.fields.person);
    return resolveEntityLink({ entry: person, domain, site, includes }); // Recursively call resolveEntityLink for nested person
  }

  // Handle PersonWrapperEntry case
  if (isContentType<PersonWrapperEntry>("personWrapper", entity)) {
    const bioUrlEntry =
      resolveEntry(includes, entity.fields.bioURL) ||
      resolveEntry(
        includes,
        resolveEntry(includes, entity.fields.person)?.fields?.bioURL,
      );
    if (bioUrlEntry) {
      return domain
        ? resolveEntryUrl({ domain, entry: bioUrlEntry, includes }) || ""
        : bioUrlEntry?.fields?.url || "";
    }
  }

  // Prevent link generation for past HBS faculty
  const personReference = isContentType<PersonWrapperEntry>(
    "personWrapper",
    entity,
  )
    ? entity.fields.person
    : entity;
  if (resolveIsPersonPastHbsFaculty({ entry: personReference, includes })) {
    return ""; // Short-circuit if person is past HBS faculty
  }

  // Handle Working Knowledge site logic
  if (site && getSiteId(resolveEntry(includes, site)) === "working-knowledge") {
    // Safely check if entity is a PersonWrapperEntry or PersonEntry, then resolve personEntry
    const personEntry = isContentType<PersonWrapperEntry>(
      "personWrapper",
      entity,
    )
      ? resolveEntry(includes, entity.fields.person)
      : undefined;

    const personType = personEntry?.fields?.personType;
    const isFaculty = resolveEntries({ includes, refs: personType }).some(
      (type) => type.fields.title === "HBS Faculty",
    );

    if (isFaculty) {
      const personWrapperUrl = resolveEntryUrl({
        entry: resolveEntry(includes, entity.fields.bioURL),
        domain: "library",
        includes,
        absolute: true,
      });
      if (personWrapperUrl) return personWrapperUrl; // Short-circuit if found
    }
  }

  return ""; // Default return if no URL was resolved
}
