import {
  OrganizationEntry,
  PersonEntry,
  PersonWrapperEntry,
  SchoolLeadershipPersonDetailsEntry,
} from "frontend/contentful/schema/semantics";
import { ContentfulIncludes, EntryRef } from "../schema/sys";
import { resolveEntry } from "./resolve-includes";
import { isContentType } from "frontend/contentful/lib/is-content-type";

interface ResolvePersonNameArgs {
  entry?: EntryRef<
    | PersonEntry
    | PersonWrapperEntry
    | SchoolLeadershipPersonDetailsEntry
    | OrganizationEntry
  >;
  includes: ContentfulIncludes;
}

export function resolveEntityName({
  entry,
  includes,
}: ResolvePersonNameArgs): string {
  if (!entry) return "";

  let entity = resolveEntry(includes, entry);

  if (!entity) return "";

  // Handle SchoolLeadershipPersonDetailsEntry
  if (
    isContentType<SchoolLeadershipPersonDetailsEntry>(
      "schoolLeadershipPersonDetails",
      entity,
    )
  ) {
    if (entity.fields.personName) return entity.fields.personName;
    entity = resolveEntry(includes, entity.fields.person);
  }

  // Handle PersonWrapperEntry
  if (isContentType<PersonWrapperEntry>("personWrapper", entity)) {
    return (
      entity.fields.displayName ||
      resolveEntry(includes, entity.fields.person)?.fields.displayName ||
      ""
    );
  }

  // Handle OrganizationEntry
  if (isContentType<OrganizationEntry>("organization", entity)) {
    return entity.fields.title || "";
  }

  // Handle generic PersonEntry
  if (entity?.fields.displayName) return entity.fields.displayName;

  // Construct full name for PersonEntry
  const fields = isContentType<PersonWrapperEntry>("personWrapper", entity)
    ? resolveEntry(includes, entity.fields.person)?.fields
    : entity?.fields;

  return (
    [
      fields?.firstName,
      fields?.middleName,
      fields?.lastName,
      fields?.nameSuffix,
    ]
      .filter(Boolean)
      .join(" ") || ""
  );
}
